export const environment = {
  production: true,
  authDomain: 'auth.novels-ai.com',
  API_URL_ADD_CHAPTER: 'https://add-chapter-i3rsqvn75a-lm.a.run.app/',
  API_URL_GENERATE_NEW_NOVEL: 'https://generate-audiobook-i3rsqvn75a-lm.a.run.app/',
  API_URL_REMOVE_NOVEL: 'https://delete-audiobook-i3rsqvn75a-lm.a.run.app/',
  
  STORIES_PER_PAGE: 5,
  NEW_USER_POINTS: 1,
  SUBSCRIPTION_PRICE_ID: 'price_1QGmloBPvg43OlrWJyM4fMvY',
  STRIPE_TEST_MODE: false,
  POINTS_PRICE_ID: 'price_1MvLYABPvg43OlrWhK03okqu',
};
